/** @format */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: var(--backdrop-color);
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: var(--background-color);
  padding: 2rem;
  text-align: center;

  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 500px) {
  .container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    overflow: hidden;
    border-radius: 0.5rem;
    box-shadow: var(--modal-box-shadow);

    min-width: 350px;
    width: 100%;
    height: 100%;
    max-width: 30rem;
    max-height: 36.5rem;
  }
}

.container > .close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  padding: 0.5rem;
  border: unset;
  background-color: transparent;
  box-sizing: content-box;
  box-shadow: unset;
  cursor: pointer;
}

.container > * {
  z-index: 2;
  color: var(--text-color);
}

.container h2 {
  margin: 0;
}

.container .body {
  flex: 1 1 auto;
}

.container .actions {
  flex: 0 0 auto;

  gap: 1rem;
  display: flex;
  flex-wrap: nowrap;
}

.container .actions button {
  flex: 1 1 auto;
}
