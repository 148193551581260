/** @format */

.body {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1rem;
}

.body > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
