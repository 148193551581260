/** @format */

header {
  padding: 0.75rem;
  position: relative;
  box-shadow: var(--nav-box-shadow);

  display: flex;
  align-items: center;
  justify-content: space-between;
}

header > div:first-child {
  display: none;
}

@media only screen and (min-width: 500px) {
  header > div:first-child {
    width: 8.5rem;
    display: flex;
    justify-content: flex-start;
  }
}

header > h1 {
  font-size: 1.5rem;
}

header > div:last-child {
  width: 8.5rem;
  gap: 0.5rem;
  display: flex;
  justify-content: flex-end;
}
