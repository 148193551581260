/** @format */

main {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-width: 500px;
  margin: auto;
}

main > h1 {
  margin: 1.25rem;
}

main > .actions {
  padding: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

main > .actions > input {
  flex: 0 0 100%;
}

main > .actions > button:first-child {
  flex: 0 0 auto;
}

main > .actions > button:last-child {
  flex: 1 1 auto;
}
