/** @format */

.container {
  background-color: var(--background-color);
  color: var(--text-color);
  border-radius: 0.5rem;
  padding: 10px 20px;
  border: unset;
  font-weight: bold;
  box-shadow: var(--button-box-shadow);
  cursor: pointer;
  text-transform: capitalize;
  min-height: 3rem;
}

.container:hover {
  background-color: rgba(0, 0, 0, 0.15);
  color: var(--text-color);
}

.container.small {
  min-height: unset;
}
