/** @format */

:root {
  --theme: "light";
  --text-color: #333;
  --background-color: #f7f9fa;
  --font-family: "Montserrat", sans-serif;
  --backdrop-color: rgba(255, 255, 255, 0.75);
  --card-overlay-color: rgba(255, 255, 255, 0.25);

  --input-box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  --card-box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  --nav-box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  --modal-box-shadow: rgba(17, 17, 26, 0.05) 0px 0px 16px,
    rgba(17, 17, 26, 0.05) 0px 0px 24px, rgba(17, 17, 26, 0.05) 0px 0px 56px;
  --button-box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

body.dark {
  --theme: "dark";
  --text-color: #c8c3bc;
  --background-color: #172024;
  --font-family: "Montserrat", sans-serif;
  --backdrop-color: rgba(0, 0, 0, 0.75);
  --card-overlay-color: rgba(0, 0, 0, 0.25);
  --input-border-color: rgba(255, 255, 255, 0.16);

  --input-box-shadow: rgba(255, 255, 255, 0.08) 0px 4px 12px;
  --card-box-shadow: rgba(238, 238, 229, 0.05) 0px 1px 0px,
    rgba(238, 238, 229, 0.1) 0px 0px 8px;
  --nav-box-shadow: rgba(222, 220, 217, 0.1) 0px 10px 10px -10px;
  --modal-box-shadow: rgba(238, 238, 229, 0.05) 0px 0px 16px,
    rgba(238, 238, 229, 0.05) 0px 0px 24px,
    rgba(238, 238, 229, 0.05) 0px 0px 56px;
  --button-box-shadow: rgba(255, 255, 255, 0.18) 0px 2px 4px;
}

html,
body {
  height: 100%;
  color: var(--text-color);
  background-color: var(--background-color);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

* {
  box-sizing: border-box;
}
