/** @format */
.body > h2 {
  margin-bottom: 0.5rem;
}

.body > ul {
  margin: 1rem 0;
  padding: 0 2rem;
}

.body > ul > li {
  margin: 1rem 0;
}

.body > h3 {
  margin-top: 4rem;
}
