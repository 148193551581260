/** @format */

.input {
  width: 100%;
  height: 3rem;
  font-size: 1rem;
  padding: 0.5rem;
  text-align: center;
  border-style: unset;
  border-radius: 0.75rem;
  background-color: var(--backdrop-color);
  border-color: var(--input-border-color);
  box-shadow: var(--input-box-shadow);
  color: var(--text-color);
}

.input:focus {
  outline: none;
}

input.input[value]:not([value=""]) {
  font-weight: bold;
}
