/** @format */

.container {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  justify-content: flex-start;
  gap: 0.5rem;
  height: 15rem;
  padding: 0 0.5rem;
  align-items: center;
}

.container > div {
  position: relative;
  flex: 0 1 20%;
  height: 15rem;
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: var(--card-box-shadow);
}

.container > div::before {
  content: "?";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.container > div > div:first-child {
  height: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.container > div > div:first-child:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--card-overlay-color);
}

.container > div > div:last-child {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.container > div > div:last-child > svg {
  font-size: 3rem;
}

.container > div > div:last-child > .success {
  stroke: green;
}

.container > div > div:last-child > .danger {
  stroke: red;
}

.container > span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
